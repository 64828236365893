import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Nasıl oynanır?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Günün 3 harfli kelimesini 6 denemede bulun. Her denemeden sonra,
      kutuların rengi değişerek tahmininizin ne kadar yakın olduğunu 
      gösterecektir.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="K"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="L" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        K harfi harfi doğru yerde.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="İ" />
        <Cell value="Y" 
          isRevealing={true}
          isCompleted={true}
          status="present"
        />
        <Cell
          value="İ"
        />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Y harfi kelimede var, fakat yanlış yerde.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="K" />
        <Cell value="O" />
        <Cell isRevealing={true} isCompleted={true} value="Ş" status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Ş harfi kelimede yok.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        3Harfli oyunu, açık kaynak kodlu 
        {' '}<a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline"
        >
          react-wordle
        </a>{' '}
        kullanarak yapılmıştır.
      </p>
    </BaseModal>
  )
}
