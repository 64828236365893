import { FireIcon, HashtagIcon } from "@heroicons/react/outline"
import { delimiter } from "path"
import { isArrayLiteralExpression, skipPartiallyEmittedExpressions } from "typescript"

const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}


export const WORDS = [
  //'Muvaffakiyetsizleştiricileştiriveremeyebileceklerimizdenmişsinizcesine',
  //'İNSAFSIZ'
  'ECE',
  'SEN',
  'HEP',
  'ÇOK',
  'SEV',
  'VAY',
  'ZAM',
  'FAL',
  'EGO',
  'HIZ',
  'LİG',
  'ÖRF',
  'SAĞ',
  'PÜR',
  'İLK',  
  'RUH',
  'TAT',
  'SAZ',
  'TOK',
  'SÖZ',
  'CAN',
  'PES',
  'RUS',
  'BAT',
  'KOÇ',
  'YAP',
  'KUŞ',
  'DEM',
  'HÜR',
  'EGE',
  'TEK',
  'BİR',
  'KUL',
  'MAÇ',
  'CEM',
  'DİL',
  'KOR',
  'ATA',
  'ZIT',
  'NAM',
  'YÜZ',
  'DÜN',
  'YAR',
  'AŞK',
  'SÜT',
  'IRK',
  'ISI',
  'SAZ',
  'SON',
  'ULU',
  'HAL',
  'BAL',
  'DUT',
  'İYİ',
  'SOY',
  'ZİL',
  'KIL',
  'ÇÖP',
  'ALT',
  'DAĞ',
  'KIŞ',
  'ÖLÜ',
  'YÜZ',
  'NAL',
  'ZAM',
  'PİS',
  'ÖCÜ',
  'KAN',
  'ÇAĞ',

].map(word => localeAwareLowerCase(word))

