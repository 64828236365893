export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

//export const WIN_MESSAGES = ['Great Job!', 'Awesome', 'Well done!']
export const WIN_MESSAGES = ['Tebrikler!', 'Bravo!', 'Harika!']
//export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const GAME_COPIED_MESSAGE = 'Oyun panoya kopyalandı'
//export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Yeterli harf yok'
//export const WORD_NOT_FOUND_MESSAGE = 'Word not found'
export const WORD_NOT_FOUND_MESSAGE = 'Kelime bulunamadı'
//export const HARD_MODE_ALERT_MESSAGE =
//  'Hard Mode can only be enabled at the start!'
export const HARD_MODE_ALERT_MESSAGE =
  'Zor mod sadece oyun başlangıcında açılabilir'
//export const HARD_MODE_DESCRIPTION =
//  'Any revealed hints must be used in subsequent guesses'
export const HARD_MODE_DESCRIPTION =
  'Çıkan ipucları sonraki tahminlerde kullanılmalıdır'
//export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Yüksek kontrast modu'
export const DARK_MODE_DESCRIPTION = "Koyu arka plan rengi"
//export const CORRECT_WORD_MESSAGE = (solution: string) =>
//  `The word was ${solution}`
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Doğru kelime ${solution}`
//export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
//  `Must use ${guess} in position ${position}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `${guess} harfini ${position} numaralı pozisyonda kullanmalısınız`
//export const NOT_CONTAINED_MESSAGE = (letter: string) =>
//  `Guess must contain ${letter}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
//export const ENTER_TEXT = 'Enter'
export const ENTER_TEXT = 'Enter'
//export const DELETE_TEXT = 'Delete'
export const DELETE_TEXT = 'Sil'
//export const STATISTICS_TITLE = 'Statistics'
export const STATISTICS_TITLE = 'İstatistikler'
//export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const GUESS_DISTRIBUTION_TEXT = 'Tahmin dağılımı'
//export const NEW_WORD_TEXT = 'New word in'
export const NEW_WORD_TEXT = 'Yeni kelime'
//export const SHARE_TEXT = 'Share'
export const SHARE_TEXT = 'Paylaş'
//export const TOTAL_TRIES_TEXT = 'Total tries'
export const TOTAL_TRIES_TEXT = 'Toplam deneme'
//export const SUCCESS_RATE_TEXT = 'Success rate'
export const SUCCESS_RATE_TEXT = 'Başarı oranı'
//export const CURRENT_STREAK_TEXT = 'Current streak'
export const CURRENT_STREAK_TEXT = 'Seri başarısı'
//export const BEST_STREAK_TEXT = 'Best streak'
export const BEST_STREAK_TEXT = 'Seri rekoru'